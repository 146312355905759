
import Vue from "@/interfaces/vue";
import { Messages, Therapy } from "@/interfaces/therapy";
import { Component, Prop, Watch } from "vue-property-decorator";
import { DataDownloader, get, post } from "@/api";

@Component({})
export default class TherapyMessenger extends Vue {
  @Prop({})
  therapy!: Therapy;

  messages = new DataDownloader<Messages>();
  newMessage = "";
  lastMessageId: number | null = null;
  therapistPhoto = "";
  display = false;

  baseUrl = process.env.VUE_APP_TARGET;

  interval!: any;

  get displayMessages() {
    if (this.messages.data)
      return this.messages.data.results.sort((a, b) => {
        if (new Date(a.created) > new Date(b.created)) return 1;
        else if (new Date(a.created) < new Date(b.created)) return -1;
        else return 0;
      });
    else return [];
  }

  get user() {
    return this.$store.state.user;
  }

  mounted() {
    if (this.therapy) this.getMessages();
  }

  @Watch("therapy")
  onTherapyChange() {
    this.getMessages();
  }

  async getMessages() {
    this.display = false;
    this.messages.endpoint = () => get.messages(this.therapy.id, "");
    await this.messages.update();
    if (this.interval) clearInterval(this.interval);
    const { data } = await get.therapist(this.therapy.therapist);
    this.therapistPhoto = data.photo;
    this.display = true;
    this.$nextTick(this.scrollMessages);
    this.interval = setInterval(async () => {
      await this.messages.update();
    }, 5000);
  }

  destroyed() {
    clearInterval(this.interval);
  }

  scrollMessages() {
    const container = this.$el.querySelector("#messages");
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }

  async sendMessage() {
    try {
      const { data: message } = await post.sendMessage(
        this.therapy.id,
        this.newMessage
      );
      this.newMessage = "";

      if (this.messages.data) {
        this.messages.data.results.unshift(message);
        this.lastMessageId = message.id;
        this.$nextTick(this.scrollMessages);
      }
    } catch (err) {
      console.log(err);
    }
  }
}
